:root {
  --primary-theme-color: #5271fe;
  --white-theme-color: #fff;
  --primary-rgb-theme-color: 74, 196, 243;
}

/* button desgin */
.overview-block-ptb .button {
  padding: 14px 28px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  /* transition: all 0.4s ease; */
  text-transform: uppercase;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15); /* Soft shadow */
}

/* Exclusive Gradient Button (JOIN CONTESTS) */
.overview-block-ptb .button-gradient {
  background: linear-gradient(135deg, rgb(80, 111, 252), rgb(80, 111, 252));
  color: white;
  /* border: 2px solid transparent; */
}

/* Frosted Glass Effect Button (SOLVE PROBLEMS) */
.overview-block-ptb .button-glass {
  margin-left: 20px;
  background: rgba(255, 255, 255, 0.15);
  color: #0074ff;
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(12px); /* Glass effect */
  -webkit-backdrop-filter: blur(12px);
}

.overview-block-ptb .button-glass:hover {
  /* background: rgba(255, 255, 255, 0.3); */
  /* transform: translateY(-2px); */
  /* box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  border-color: rgba(255, 255, 255, 0.6); */
}
