img {
    max-width: 100%;
    height: auto;
}
        section {
            padding: 60px 0; 
        }
 ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
.contact-area {
    border-bottom: 1px solid #353C46;
    background-color: #1a1f63;
}

.contact-content p {
    font-size: 15px;
    margin: 30px 0 60px;
    position: relative;
}
 
.contact-content img {
    max-width: 210px;
}

footer img {
    width: 44px;
}