@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
}

.DpcOrc {
  font-family: "Product Sans", "Google Sans";
  font-size: 1.1rem;
  color: #5c5f5e;
  height: 32px;
  line-height: 32px;
  text-transform: lowercase;
  font-weight: bold;
}

.custom-search-button {
  background-color: #5271ff;
  color: white;
  border: none;
  border-top-right-radius: 6px; /* Add border radius to the top-right corner */
  border-bottom-right-radius: 6px; /* Add border radius to the bottom-right corner */
  padding: 12px 16px;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.custom-search-button:hover {
  background-color: #405ccf; /* Darker shade of your company color */
}

.custom-search-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(82, 113, 255, 0.5);
}
.ant-input::placeholder {
  color: lightgray !important; /* Ensure it applies universally */
  opacity: 1; /* Prevent default opacity reduction */
}

/* hide unhide duplicats */

.smooth-transition {
  transition: all 0.3s ease-in-out;
}

.hidden {
  opacity: 0;
  transform: scale(0.95);
  pointer-events: none;
}

.visible {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}

/* table css */

.custom-table .ant-table-thead > tr > th {
  background-color: #5271ff;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.custom-table .ant-table-tbody > tr:hover > td {
  background-color: rgba(82, 113, 255, 0.1);
  transition: background-color 0.3s ease;
}
